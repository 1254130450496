
/* .cart {
  align-items: flex-start;
  background-color: #e0eef7;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  overflow: hidden;
  padding: 33px 24px;
  position: relative;
  width: 643px;
}

.cart-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-right: -5px;
  padding: 0px 4px;
  position: relative;
  width: 600px;
} */

.orders {
  align-items: flex-start;
  background-color: #e0eef7;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  overflow: hidden;
  padding: 33px 24px;
  position: relative;
  width: 1027px;
}

.orders .orders-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 4px;
  position: relative;
  width: 977px;
}

.text-wrapper-2 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.7px;
  margin-top: -1px;
  opacity: 0.5;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-5 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.7px;
  opacity: 0.5;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (max-width: 1060px) {
  .orders .orders-title {
    width: 88vw;
  }  
}

@media only screen and (max-width: 600px) {
  .orders {
    align-items: center;
    background-color: #e0eef7;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: center;
    overflow: hidden;
    
    position: relative;
    
  }
}