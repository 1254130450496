.payment-successful-page {
  display: flex;
  flex-direction: column;
  padding-top: 4vh;
  background-color: #f1fafe;
  height: 100vh;
  overflow: auto;
}

.payment-successful-illus-container {
  background-image: url(/public/img/group-11917.png);
  background-size: 100% 100%;
  /* height: 201.69px; */
  position: relative;
  margin-top: 2vh;
  width: 1004px;
}

@media only screen and (max-width: 600px) {
  .payment-successful-page {
    overflow-x: hidden;
  }
}