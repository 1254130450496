/* .checkout-summary-page {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 62px 63px;
  position: relative;
  width: 98vw;
  height: fit-content;
}

.checkout-summary-page .checkout {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
} */

.checkout {
  display: flex;
  flex-direction: column;
  padding: 4%;
  padding-top: 2%;
  background-color: #f1fafe;
  width: fit-content;
}

@media only screen and (max-width : 430px) {
  .checkout {
    width: 100%;
    padding-right: 5%;
  }
}