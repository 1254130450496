
.pdf-container{
    width: 100%;
    height: 80vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  
  
  .logo{
    position: absolute;
    height: 100px;
    width: 100px;
    top: 100px;
    left: 100px;
  }
  
  .bg{
     z-Index: 0px;
          position: "absolute";
          height: 100%;
          width: 100%,
  }
  
  .header{
    width: '100%';
    padding: 10px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: Cardo;
    font-size:x-large;
    font-weight: bold;
    color: #2C6AB1;
    align-items: center;
  }

  
  .back{
    cursor: pointer;
    font-family: Cardo;
    opacity: 90%;
    font-size:x-large;
    color: #2C6AB1;
    display: flex;
  }
  
  .container{
    height: 75vh;
    margin-top: 30px;
    border:  solid transparent;
    border-radius: 20px;
    background-color: #F3F3F3;
    box-shadow: 0 0 0 30px white; 
    overflow: hidden;
  }

  .body{
 /* background-color: #F3F3F3; */
 background-color: #f1fafe;
 /* margin: auto; */
 padding: 10px 20%;
 background-image: url(../../Assets/images/bg.png);
 background-position: top;
 /* height: 100vh; */
  }
  
  form{
    margin-top: 20px;
    text-align: center;
  }
  
  .btn{
    margin-top: 10px;
  }
  
  @media print{
    .body{
      display: none;
    }
  }



