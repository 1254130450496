@media only screen and (min-width: 1250px) {

  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    position: relative;
    /* width: 100%; */
    width: fit-content;
    margin-top: 5vh;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1249px) {
  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 5px;
    position: relative;
    width: 100%;
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 430px) {
  .cart-subtitle {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    /* flex: 0 0 auto; */
    flex-wrap: wrap;
    justify-content: left;
    gap: 5px;
    position: relative;
    width: 90%;
    margin-top: 5vh;
    margin-left: 3%;
  }
}