
.subtotal-text-wrapper-3 {
  font-weight: 700;
}

.subtotal {
  align-items: center;
  background-color: #e0eef7;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 240px;
  justify-content: center;
  overflow: hidden;
  padding: 24px 25px;
  position: relative;
  width: 300px;
}

.group-2 {
  height: 145.72px;
  margin-left: -2.75px;
  margin-right: -2.75px;
  margin-top: -4.36px;
  position: relative;
  width: 302.5px;
}

.frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.frame-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  position: relative;
  width: 302px;
}

.div-wrapper {
  height: 35px;
  position: relative;
  width: 288px;
}

.frame-3 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 288px;
}

.text-wrapper-8 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-2 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 34.7px;
  margin-top: -1px;
  opacity: 0.9;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-9 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 27px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 34.7px;
}

.text-wrapper-10 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 23.1px;
  opacity: 0.6;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.subtotal-button {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 93px;
  width: 302px;
  cursor: pointer;
}

.subtotal-button:disabled {
  opacity: 0.5;
  cursor:auto;
}

.overlap-group-wrapper {
  height: 52.72px;
  margin-right: -1px;
  position: relative;
  width: 302.5px;
}

.overlap-group-3 {
  background-color: #023b8f;
  border: 1px solid;
  border-color: #8a8a8a;
  border-radius: 11px;
  height: 53px;
  position: relative;
  width: 300px;
}

.text-wrapper-11 {
  color: #ffffff;
  font-family: "Cardo", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 19px;
  left: 66px;
  letter-spacing: 0;
  line-height: 23.1px;
  position: absolute;
  text-align: center;
  top: 15px;
  white-space: nowrap;
  width: 166px;
}

.you-will-gain-access {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
  margin-bottom: -3.36px;
  opacity: 0.64;
  position: relative;
  text-align: center;
  width: fit-content;
}

@media only screen and (max-width : 430px) {
  .subtotal {
    width: 90%;
    margin-top: 20px;
  }
}

@media only screen and (max-width : 300px) {
  .subtotal {
    width: 80%;
    overflow-x: scroll;
    /* justify-content: left; */
    /* justify-self: left; */
    /* align-content: flex-start; */
    flex-wrap: wrap;
  }
}