.checkout-summary-page {
  display: flex;
  width: auto;
  justify-content: center;
  background-color: #f1fafe;
}

@media only screen and (min-width: 1351px) {

  .universal-text-wrapper {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-text-wrapper-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-div {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-div-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-header-summary {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 23px;
    justify-content: center;
    position: relative;
  }

  .universal-NFO-logo {
    height: 89px;
    object-fit: cover;
    position: relative;
    width: 203px;
    margin-right: 10px;
  }

  .universal-checkout-header {
    align-items: flex-end;
    /* background-color: transparent; */
    display: flex;
    justify-content: space-between;
    /* gap: 560px; */
    justify-self: center;
    position: relative;
    /* width: 100vw; */
    top: 2px;
  }

  .universal-checkout-header-frame {
    /* align-items: center; */
    display: inline-flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: 30px;
    justify-content: center;
    position: relative;
  }

  .universal-checkout-header-group {
    height: 75.17px;
    margin-left: -3.42px;
    position: relative;
    width: 75.17px;
  }

  .universal-item-remove-button {
    width: fit-content;
    padding: 10px 10px;
    border-radius: 40px;
    background-color: #D7EAFF66;
    border: 1px solid #ACC2DB99;
    cursor: pointer;
    font-family: Cardo;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #2C6AB1;
  }
}

@media only screen and (min-width: 1251px) and (max-width: 1350px) {
  .universal-text-wrapper {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-text-wrapper-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-div {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-div-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-header-summary {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 23px;
    justify-content: center;
    position: relative;
  }

  .universal-NFO-logo {
    height: 89px;
    object-fit: cover;
    position: relative;
    width: 203px;
  }

  .universal-checkout-header {
    align-items: flex-end;
    /* background-color: transparent; */
    display: flex;
    justify-content: space-between;
    gap: unset !important;
    justify-self: center;
    position: relative;
    /* width: 100vw; */
    top: 2px;
  }

  .universal-checkout-header-frame {
    /* align-items: center; */
    display: inline-flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: 30px;
    justify-content: center;
    position: relative;
  }

  .universal-checkout-header-group {
    height: 75.17px;
    margin-left: -3.42px;
    position: relative;
    width: 75.17px;
  }

  .universal-item-remove-button {
    width: fit-content;
    padding: 10px 10px;
    border-radius: 40px;
    background-color: #D7EAFF66;
    border: 1px solid #ACC2DB99;
    cursor: pointer;
    font-family: Cardo;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #2C6AB1;
  }
}

@media only screen and (min-width: 431px) and (max-width: 1250px) {

  .universal-text-wrapper {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-text-wrapper-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-div {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-div-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-header-summary {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 23px;
    justify-content: center;
    position: relative;
  }

  .universal-NFO-logo {
    height: 89px;
    object-fit: cover;
    position: relative;
    width: 203px;
  }

  .universal-checkout-header {
    align-items: flex-end;
    /* background-color: transparent; */
    display: flex;
    justify-content: space-between;
    justify-self: center;
    position: relative;
    /* width: 100vw; */
    top: 2px;
  }

  .universal-checkout-header-frame {
    /* align-items: center; */
    display: inline-flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: 30px;
    justify-content: center;
    position: relative;
  }

  .universal-checkout-header-group {
    height: 75.17px;
    margin-left: -3.42px;
    position: relative;
    width: 75.17px;
  }

  .universal-item-remove-button {
    width: fit-content;
    padding: 10px 10px;
    border-radius: 40px;
    background-color: #D7EAFF66;
    border: 1px solid #ACC2DB99;
    cursor: pointer;
    font-family: Cardo;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #2C6AB1;
  }
}

@media only screen and (max-width: 430px) {

  .checkout-summary-page {
    display: flex;
    /* width: 100vw; */
    overflow-x: hidden;
  }

  .universal-text-wrapper {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-text-wrapper-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36.6px;
    margin-top: -1px;
    opacity: 0.9;
    position: relative;
    /* width: 514px; */
  }

  .universal-div {
    color: #023b8f;
    font-family: "Cardo", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-div-red {
    color: rgba(143, 19, 2, 1);
    font-family: "Cardo", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-right: -186px;
    opacity: 0.5;
    position: relative;
    /* width: 543px; */
  }

  .universal-header-summary {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    position: relative;
    margin-top: 10px;
    text-align: center;
  }

  .universal-NFO-logo {
    display: none !important;
    height: 89px;
    object-fit: cover;
    position: relative;
    width: 203px;
  }

  .universal-checkout-header {
    align-items: center;
    /* background-color: transparent; */
    display: flex;
    justify-content: center;
    justify-self: center;
    position: relative;
    width: 100vw;
    top: 2px;
    margin-left: 10px;
  }

  .universal-checkout-header-frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    /* gap: 30px; */
    justify-content: center;
    position: relative;
  }

  .back-to-portal-button {
    align-items: center;
    width: fit-content;
  }

  .universal-checkout-header-group {
    /* height: 75.17px; */
    height: 50px;
    margin-left: -3.42px;
    position: relative;
    /* width: 75.17px; */
    width: 50px;
  }

  .universal-item-remove-button {
    width: fit-content;
    padding: 10px 10px;
    border-radius: 40px;
    background-color: #D7EAFF66;
    border: 1px solid #ACC2DB99;
    cursor: pointer;
    font-family: Cardo;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #2C6AB1;
  }
}