.order-subtitle {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  gap: 2vw;
  position: relative;
  width: 100%;
  margin-top: 5vh;
}

@media only screen and (max-width: 1060px) {
  .order-subtitle {
    width: 96vw;
    margin-left: 2vw;
  }
  
}

/* .order-subtitle {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 86px;
  position: absolute;
  top: 208px;
  width: 1027px;
} */