/* .order {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 156px;
  justify-content: center;
  margin-right: -5px;
  overflow: hidden;
  padding: 20px 28px 17px;
  position: relative;
  width: 600px;
}

.wrap {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 47px;
  margin-bottom: -4.75px;
  margin-left: -21.5px;
  margin-right: -21.5px;
  margin-top: -4.75px;
  position: relative;
  width: 587px;
}

.item-content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.item-pic {
  background-image: url(/public/img/image-21.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 128.5px;
  position: relative;
  width: 137px;
}

.item-title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
} */

.order {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: 156px; */
  height: fit-content;
  justify-content: center;
  overflow: hidden;
  padding: 20px 28px 19px;
  position: relative;
  width: 977px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.order-wrap {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  /* gap: 282px; */
  justify-content: space-between;
  margin-bottom: -5.75px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  margin-top: -5.75px;
  position: relative;
  width: 934px;
  /* overflow-x: scroll; */
}

.order-content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.order-content-wrap {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}
.order-pic {
  /* background-image: url(/public/img/image-21.png);
  background-position: 50% 50%;
  background-size: cover; */
  height: 150px;
  /* position: relative; */
  width: 150px;
  object-fit: contain;
  margin-right: 10px;
}

.order-title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.order .title {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.2px;
  margin-top: 10px;
  opacity: 0.9;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.order .description {
  color: #666666;
  font-family: "Cardo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.5px;
  opacity: 0.9;
  position: relative;
  /* width: fit-content; */
  width: 600px;
  /* height: fit-content; */
}

.order .order-frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  height: 104px;
  justify-content: center;
  position: relative;
}

.order .order-group {
  height: 51.5px;
  position: relative;
  width: 190.5px;
}

.order .order-overlap-group-wrapper {
  height: 52px;
  width: 192px;
}

.order .order-overlap-group {
  height: 51px;
  position: relative;
  width: 190px;
}

.order .order-rectangle {
  background-color: #023b8f;
  border: 0.69px solid;
  border-color: #8a8a8a;
  border-radius: 14.53px;
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 190px;
  cursor: pointer;
  justify-content: center;
}

.order .order-text-wrapper-3 {
  color: #ffffff;
  font-family: "Cardo", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20.5px;
  position: relative;
}

.order .order-text-wrapper-4 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
  opacity: 0.9;
  position: relative;
  white-space: nowrap;
  text-align: center;
  text-wrap: wrap;
  cursor: pointer;
}

.vis-invis-showing-amount {
  display: none !important;
}

@media only screen and (max-width: 1060px) {
  .order-wrap {
    width: 100%;
    justify-content: space-between;
    gap: unset !important;
    margin: unset !important;
  }

  .order {
    width: 90%;
    margin-right: 5px;
    height: fit-content;
  }

}

@media only screen and (max-width: 600px) {
  
  .order-title {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 5px;
    width: 250px;
    height: fit-content;
  }

  .order .title { 
    white-space: pre-wrap;
  }

  
  .order-title .description {
    color: #666666;
    font-family: "Cardo", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20.5px;
    opacity: 0.9;
    position: relative;
    width: fit-content;
  }

/*-----------*/


  .order {
    align-items: center;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: unset !important;
    justify-content: center;
    margin-right: -5px;
    overflow: hidden;
    padding: 10px 10px 10px 10px;
    position: relative;
    width: 95%;
  }


  .order .order-frame{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .vis-invis-showing-amount {
    display: flex !important;
  }
  .order-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    gap: unset;
    justify-content: space-between;
    margin-bottom: -4.75px;
    margin-left: -21.5px;
    margin-right: -21.5px;
    margin-top: -4.75px;
    position: relative;
    width: 100%;
    overflow-x: scroll;
  }

  .order-content {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
  
  .order-content-wrap {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
    justify-content: flex-start;
  }

  .order-pic {
    display: none !important;
  }

  .order .order-rectangle {
    background-color: #023b8f;
    border: 0.69px solid;
    border-color: #8a8a8a;
    border-radius: 14.53px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: unset !important;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    justify-content: center;
  }
}
