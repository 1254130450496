/* .mobileUserInfoModal {
  display: none;
} */

.item-info-wrap {
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 600px;
  margin-left: 5%;
  margin-right: 5%;
}

.item-info-back-button {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.item-info-container {
  display: flex !important;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  justify-content: left;
  align-content: flex-start;
  text-align: left;
  font-family:'Cardo';
  font-size: 24px;
  font-weight: 400 !important;
  line-height: 33px;
  color: rgba(44, 106, 177, 1);
  /* margin-top: 15px; */
  gap: 10px;
  /* width: 200px; */
  /* height: 30%; */
  align-items: center;
}

/* .item-info-image { 
} */

.item-info-title {
  font-family: Cardo;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

.item-info-desc {
  font-family: Cardo;
  font-size: 17px;
  font-weight: 700;
  line-height: 21.83px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
}

.item-info-additembutton {
  height: 34px;
  margin-right: -2px;
  margin-top: 10px;
  position: relative;
  /* width: 116px; */
  display: flex;
  justify-content: center;
}

.item-info-overlap {
  border: none;
  border-radius: 11.69px;
  height: 34px;
  position: relative;
  width: 114px;
  cursor: pointer;
  background-color: rgba(2, 59, 143, 0.1);
}

.item-info-text-wrapper-7 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 76px;
}

@media only screen and (max-width: 430px) {
  .item-info-wrap {
    top: unset;
  }
}