.footer {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
}

.illus-container {
  background-image: url(/public/img/group-11917.png);
  background-size: 100% 100%;
  /* height: 201.69px; */
  position: relative;
  width: 1004px;
}

.text-wrapper-12 {
  color: #8e8e8e;
  font-family: "Cardo", Helvetica;
  font-size: 20.3px;
  font-weight: 700;
  /* left: 269px; */
  letter-spacing: 0;
  line-height: 27.9px;
  opacity: 0.88;
  position: relative;
  text-align: center;
  /* width: 493px; */
  width: 100%;
}

.cancel-policy-button {
  height: 22px;
  /* left: 416px; */
  position: relative;
  /* top: 67px; */
  width: 174px;
  cursor: pointer;
  margin-top: 4vh;
}

.text-wrapper-13 {
  color: #023b8f;
  font-family: "Cardo", Helvetica;
  font-size: 17px;
  font-weight: 700;
  left: 20px;
  letter-spacing: 0;
  line-height: 21.8px;
  opacity: 0.64;
  position: relative;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.group-4 {
  height: 15px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}

@media only screen and (max-width : 430px) {
  .text-wrapper-12 {
    font-size: 16px;
  }
}